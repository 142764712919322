import { ContainerMaxWidth } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import React from 'react';

import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

import * as styles from './guide.module.scss';

interface TipProps {
  url: string;
  text: string;
}

const Tip = ({url, text}: TipProps) =>
  <a className={styles.seeTip} href={url}>
  <span className={styles.tip}><T id="Tip" /></span>
  <span className={styles.tipText}><T id={text} /></span>
</a>;

const Business = () => (
  <LayoutBlog>
    <SEO
      title="Hypersay Blog - Resources on Interactive Presentations"
      description="Read articles on how to build interactive presentations and how to use Hypersay effectively."
      keywords={[
        'live presentation',
        'blog',
        'knowledge',
        'learn',
        'interactive presentations',
        'business',
        'corporate',
        'training',
        'townhall meetings',
        'powerpoint',
        'google slides',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1200px">
      <article className={styles.pageContainer}>
        <img
          src={withPrefix('/images/splash-quick-guide.jpg')}
          alt="sign post showing right"
          className={styles.headerImage}
        />
        <T tagName="h1" id="Quick Guide to Hypersay" />
        <div style={{ opacity: '.7'}} ><T id="⏱3 minute read" /></div>
        <div style={{ opacity: '.7', marginTop: '0'}} ><T id="published: Dec 18, 2019" /></div>
        <T tagName="p" id="Hypersay is built on the idea of connecting presenters with participants. This guide is meant for presenters to help them get started with the essential aspects of Hypersay. There are 4 basic steps: " />
        <img src={withPrefix('/images/blog/four-steps.png')} alt="Upload slides > Add Interactions > Go Live > Check Session Analytics" />
        <T tagName="p" id="The guide below will take you all the way from opening an account to surveying the main differences from our competitors. So let's get started!" />
        <section  className={styles.sectionSpace}>
          <div id="create" className={styles.anchorTarget}></div>
          <h2><T id="1. Create account" /></h2>
          <T tagName="p" id="To become a Presenter you will need to register using your email or social media. For participants, the registration is optional (but they lose acceess to some features). You can also control, for each presentation, if you allow anonymous participants." />
          <T tagName="p" id="There is no separate registration process. Signing in, logging in or creating an account are essentially the same process. Check for the orange 'Sign in' button. The quickest way to sign in is to use social media (Facebook, Google and LinkedIn are currently available). If you prefer to sign in directly with email, you need to know that Hypersay uses a secure password-less system called One Time Password." />
          <img src={withPrefix('/images/blog/sign-in-steps.png')} alt="sign in steps" className={styles.blogImg} />
          <p>
            <span style={{ fontWeight: 'bold' }} ><T id="OTP (One Time Password) " /></span>
            <T id="works like this: once you provide your email account, we will send you a secure code in your inbox. Copy that code to confirm your account. You are in. You will have to repeat this step every time you want to sign in (new code every time). On the plus side we keep you signed in for as long as we can and you don't have to remember any passwords. " />
          </p>
          <Tip url="/blog/tips/#security" text="On account security." />
        </section>
        <section  className={styles.sectionSpace}>
          <div id="slides" className={styles.anchorTarget}></div>
          <h2><T id="2. Upload slides" /></h2>
          <T tagName="p" id="Now that you signed in, the next step is to import the slides. We currently accept PowerPoint files, Google Slides imports and PDFs. If you use Keynote just make sure to export the slides in PDF." />
          <img src={withPrefix('/images/blog/upload-ppt.gif')} className={styles.blogImg} />
          <Tip url="/blog/tips/#slidesanimations" text="On supporting animations and videos in slides." />
          <Tip url="/blog/tips/#slidesreplacement" text="On replacing or editing slides." />
        </section>
        <section  className={styles.sectionSpace}>
          <div id="interactions" className={styles.anchorTarget}></div>
          <h2><T id="3. Add interactions" /></h2>
          <T tagName="p" id="Now that you uploaded your slides, head to the 'Prepare' section. Here you can augment your slides by adding interactivities. We recommend that you add interactivies as new slides, but you can also add them below specific slides." />
          <T tagName="p" id="Types of interactivites we support:" />
          <ul>
            <T tagName="li" id="yes / no questions" />
            <T tagName="li" id="single / multiple choice polls" />
            <T tagName="li" id="opinion scales" />
            <T tagName="li" id="open questions" />
            <T tagName="li" id="interactive images" />
            <T tagName="li" id="embedded videos" />
            <T tagName="li" id="text & images" />
          </ul>
          <T tagName="p" id="Some of the interactions listed above have templates to help you speed up the setup and we support marking right / wrong answers as well." />
          <img
            src={withPrefix('/images/blog/add-opinion-scales.gif')}
            alt="adding opinion scales" className={styles.blogImg}
          />
          <T tagName="p" id="For each presentation there are some specific settings you may want to check. From here you can control, for example, whether anonymous participants are allowed to join your presentation or whether you use the question wall." />
          <img
            src={withPrefix('/images/blog/session-settings2.png')}
            alt="session settings" className={styles.blogImg}
          />
          <Tip url="/blog/tips/#betweenorbelow" text="Adding interactions: between or below slides?" />
          {/* <Tip url="/blog/tips/#effectivepresentations" text="Create effective interactions." /> */}
          <Tip url="/blog/tips/#addgifs" text="Using animated GIFs." />
          <Tip url="/blog/tips/#rearrange" text="Re-arrange slides." />
        </section>
        <section  className={styles.sectionSpace}>
          <div id="freeorpro" className={styles.anchorTarget}></div>
          <h2><T id="4. Free or upgrade to PRO?" /></h2>
          <T tagName="p" id="If you audience is going to be greater than 20 people/students/participants, you will need to purchase a credit in advance. You can purchase the credit for $3.99 from our Hypersay pricing page. The credit will appear in your account inside the dashboard. You can assign the credit to the particular Hypersay session which will allow up to 200 people/students/participants join your session." />
          <img src={withPrefix('/images/blog/buy-credits.png')} alt="buy credits" className={styles.blogImg} />
          <Tip url="/blog/tips/#creditsvssubscription" text="Credits or subscription?" />
        </section>
        <section  className={styles.sectionSpace}>
          <div id="live" className={styles.anchorTarget}></div>
          <h2><T id="5. Going live" /></h2>
          <T tagName="p" id="You've made it so far, now it's time for the actual show. When you click the red 'Go live' you, well, start the live session. What does this mean?" />
          <T tagName="p" id="It means that now your session accepts participants. People in your audience see a Welcome screen with a QR and alternative joining instructions. Once they have joined your session, they can 'Say hi'. It's fun and engaging." />
          <img src={withPrefix('/images/blog/welcome-page-mockup.png')} alt="welcome page" className={styles.blogImg} />
          <T tagName="p" id="Once your participants are connected, you are ready to 'Start the presentation'. That takes you to your first slide. From here on, you just follow your slides, use the defined interactions to get the participants engaged, show results, etc. When the presentation ended make sure you hit the 'End session' button." />
          <img src={withPrefix('/images/blog/interface-page-mockup.png')} alt="interface" className={styles.blogImg} />

          <T tagName="p" id="Ending the session takes everybody to the Feedback screen. This allows your participants to give feedback on your presentation and also allows you to give us feedback on how Hypersay performed." />
          <img src={withPrefix('/images/blog/feedback-page.png')} alt="feedback page" className={styles.blogImg} />
          <Tip url="/blog/tips/#joining" text="Getting participants to join." />
          <Tip url="/blog/tips/#clicker" text="Use a clicker." />
          <Tip url="/blog/tips/#questionwall" text="Collect and manage questions." />

        </section>
        <section  className={styles.sectionSpace}>
          <div id="moderation" className={styles.anchorTarget}></div>
          <h2><T id="6. Question moderation" /></h2>
          <img src={withPrefix('/images/blog/moderation.png')} alt="question moderation" className={styles.blogImg} />
          <T tagName="p" id="Hypersay supports moderating questions from the audience. By default, question moderation is turned 'Off' within Hypersay. Question moderation can be turned 'On' in Prepare Settings or inside the Question Wall while Live." />
          <T tagName="p" id="When turned 'On' there are two ways to moderate questions." />
          <ol>
            <T tagName="li" id="Presenters can open the moderation page from the Question Wall during a live session. The moderation page will open in a separate tab in your browser. When turned 'On', all existing and future audience questions will be pending until either accepted or rejected. Audience members will see their pending questions in grey until actioned. Presenters should hide their computer screen while moderating questions." />
            <T tagName="li" id="A colleague can act as moderator for the Question Wall. Presenters can share the moderation link with a trusted colleague who can accept or reject questions during the live presentation. The moderation link is available in Settings in the Prepare page or inside the Question Wall during a live presentation. Presenters can also open the moderation page on their phone or tablet in order to moderate the questions." />
          </ol>
          <T tagName="p" id="There is a Reset Button which allows Presenters to generate a new access code for the moderation page when needed." />
        </section>
        <section  className={styles.sectionSpace}>
          <div id="analytics" className={styles.anchorTarget}></div>
          <h2><T id="7. Discover analytics" /></h2>
          <T tagName="p" id="Once the session ended you can head to the 'Reports' section where we analyse the data available on the overall performance of your presentation. Here you can find a overview of all participants' activity: their answers, their questions, their engagement rate. There is also a 'digital pulse' graph worth checking: it shows the engagement trends in your audience. " />
          <img src={withPrefix('/images/blog/reports.gif')} alt="reports" className={styles.blogImg} />
          <Tip url="/blog/tips/#feedback" text="Where's my feedback?" />
          <Tip url="/blog/tips/#digitalpulse" text="What is the digital pulse?" />
          <Tip url="/blog/tips/#specificuser" text="How to find a specific participant's contribution?" />

        </section>
        <section  className={styles.sectionSpace}>
          <div id="others" className={styles.anchorTarget}></div>
          <h2><T id="8. Hypersay vs. Others" /></h2>
          <T tagName="p" id="Last but definitely not least, it's worth highlighting some of the features and ideas that differentiate use from most of our competition. There are a lot of good software solutions for interactive presentations out there, such as Slido, Glisser or Mentimeter." />
          <T tagName="p" id="Our 'angle' is to offer you a simple solution that combines traditional slide-based presentations with offering engaging interactions to maintain the attention of your audience but to also allow you to learn." />
          <T tagName="p" id="This is why Hypersay is an integrated solution. 'Integrated' means that you won't have to switch from slides to interactions, you have them all in our coherent experience." />
          <T tagName="p" id="And 'learn' means that we give you analytics that reflect what happened during your session. Who participated? To which degree? How much time did you spend on which slide? What interactions were the least efficient? Etc. " />
        </section>
      </article>

      <nav>
        <div className={styles.tocHeader}><T id="Contents" /></div>
        <ol>
          <li><a href="#create"><T id="Create account" /></a></li>
          <li><a href="#slides"><T id="Upload slides" /></a></li>
          <li><a href="#interactions"><T id="Add interactions" /></a></li>
          <li><a href="#freeorpro"><T id="Free or upgrade to PRO?" /></a></li>
          <li><a href="#live"><T id="Going live" /></a></li>
          <li><a href="#moderation"><T id="Question moderation" /></a></li>
          <li><a href="#analytics"><T id="Discover analytics" /></a></li>
          <li><a href="#others"><T id="Hypersay vs. Others" /></a></li>
        </ol>
      </nav>

    </ContainerMaxWidth>
  </LayoutBlog>
);

export default Business;
